import axios from "axios";

export const headers = {
	"Tenant-Access-Token": "MjA3YzA0NWVkYWM2Y2RkZGRkZDQwZmU4ZjAxODcxNWE=",
	"Tenant-Access-Key": "ZgnfjsO2Td3H0w",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers,
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
